<template lang="pug">
  .mr-navigation
    header.container(:class="[{'dark-header': showMensMenu}, headerClass]")
      .top-nav.row.text-center.xs-f-small.md-f-medium.lg-f-small.max-at-tweak(:class="{ open: topNavMobileNavOpen }")
        MrSiteSearch

        template(v-if="cmsSettings && cmsSettings.showMegaNav")
          .mega-nav.xs-f-xsmall.max-at-tweak.xs-12
            .xs-12.md-6.lg-3
              .mega-nav-item.womens-nav-item.xs-6
                a(href="").upper
                  span Women's
                .selected-strip

              .mega-nav-item.mens-nav-item.xs-6
                a(href="/men").upper
                  span Men's

        .top-wrap.xs-12
          //- show left hamburger if user fulfills any of the following
            - not logged in
            - has not made an order
          .left-wrap
            button.hamburger.lg-hide(type="button" v-if="showHamburgerMenu && showNavs" @click="toggleHamburger" :class="{ open: topNavMobileNavOpen }" data-mr-ass="hamburger-menu")
              .stripe-top
              .stripe-bottom

            template(v-if="!showMensMenu")
              // Desktop/tablet search icon shows everytime
              button.search-trigger.btn-reset.color-black.xs-hide.sm-hide.md-hide.lg-f-small.max-at-tweak(@click="toggleSearch")
                span.upper.xs-f-xsmall.md-f-small.lg-f-xsmall.max-at-tweak search&nbsp;
                span.icon-magnify

              // Mobile search icon shows only if the hamburger menu is not visible
              button.search-trigger.btn-reset.color-black.xs-f-medium.md-f-xmedium.lg-hide.max-at-tweak(v-if="!showHamburgerMenu || !showNavs" @click="toggleSearch")
                span.icon-magnify
                  span.ada-tooltip open search

          .middle-wrap
            template(v-if="showMensMenu")
              a(href="/men").mr-mister-logo.color-white
                mr-icon(name="mr-mister-logo" width="263" height="29")
                span.ada-tooltip home

            template(v-else)
              a.mr-logo(href="/" target="_self" aria-label="Go to Home Page")
                span.ada-tooltip home

          .right-wrap
            button.btn-reset.icon-account.color-black(v-if="hideLogin" target="_self" aria-label="Account" role="menuitem" aria-haspopup="true" @click.prevent="openAccountModal")
              .desktop-only(v-if="!showMobileAccountBtn")
                mr-icon(name="profile" :fill="true" height="1.1rem")
                span.xs-f-xsmall.md-f-small.lg-f-xsmall.max-at-tweak Account
              template(v-else)
                mr-icon(name="profile" :fill="true")
                span.ada-tooltip Account

            button.btn-reset.coupons.xs-mr-50m(v-if="couponCount" @click="showCouponDrawer")
              PromoTagCounter

            a.icon-bag.color-black(href="/cart" target="_self" aria-label="Cart")
              span.ada-tooltip go to cart
              .item-count
                span {{cic}}

            .login-wrap.xs-hide.sm-hide.md-hide(v-if="!hideLogin")
              button.login.btn-reset.upper.color-black.xs-f-xsmall.md-f-small.lg-f-xsmall.max-at-tweak(@click="openLogInModal" target="_self" data-mr-ass="log-in-nav" aria-label="Log In") Log In

        //- show "account" / "login" tab if user fulfills any of the following
          - not logged in
          - has not made an order
        .mr-unlogged-nav-bar(v-if="showNavs")
          template(v-if="showMensMenu")
            MensMenu(:class="{ open: topNavMobileNavOpen }" @open-sign-in-control="openLogInModal")

          template(v-else)
            site-nav

      AccountSideDrawerModal(v-if="hideLogin")
</template>

<script>
  import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
  import MrSiteSearch from '@components/mrSiteSearch';
  import trackEventMixin from "@mixins/trackEventMixin";
  import customerMixin from "@mixins/customerMixin";
  import SiteNav from "@components/SiteNav";
  import PromoTagCounter from "@components/PromoTagCounter/PromoTagCounter";
  import AccountSideDrawerModal from "./AccountSideDrawerModal/";

  export default {
    name: "MrNavigation",

    components: {
      PromoTagCounter,
      TopMenu: () => import('../Dashboard/TopMenu'),
      MensMenu: () => import('../MensMenu'),
      MrSiteSearch,
      SiteNav,
      AccountSideDrawerModal,
    },

    mixins: [
      trackEventMixin,
      customerMixin,
    ],

    inject: ['getObjProperty'],

    props: {
      usingStickyPromoDrawer: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        promoDrawerCookie: 'promoDrawerSeen',
      };
    },

    computed: {
      ...mapState('cart', ['cic']),
      ...mapState('global', [
        'windowData',
        'isApp',
        'headerClass',
        'showHamburgerMenu',
        'hideLogin',
        'showNavs'
      ]),
      ...mapState('customer', ['cdata', 'info']),
      ...mapState('cms', ['cmsSettings']),
      ...mapState('siteNav', [
        'topNavSelected',
        'topNavMobileNavOpen',
        'navItems',
      ]),
      ...mapGetters('customer', [
        'limitlessProPlus',
        'limitlessPlus',
        'limitlessPro',
        'customerHasMemberships'
      ]),
      ...mapGetters('siteNav', [
        'showMensMenu',
      ]),
      ...mapGetters('coupons', [
        'couponCount'
      ]),

      activeCdataSubs() {
        return this.getObjProperty(this, 'cdata.activeSubscriptions') || [];
      },

      hasNonMembershipSubs() {
        const membershipTypes = ['renewable_cb_membership', 'omni_memb', 'color_plus_membership'];
        return Boolean(this.activeCdataSubs.filter(subs => !membershipTypes.includes(subs.productType)).length);
      },

      hasMoreThanCanceledLimitlessMembership() {
        let canceledSubTypes = this.getObjProperty(this, 'cdata.canceledSubTypes') || {};
        return Boolean(Object.keys(canceledSubTypes).filter(subType => subType !== 'renewable_cb_membership').length);
      },

      customerInfo() {
        return this.$store.state.customer.info;
      },

      showMobileAccountBtn() {
        const { breakpoint } = this.windowData;
        return !['desktop', ''].includes(breakpoint);
      },
    },

    watch: {
      cdata(cData) {
        if (!cData) {
          return;
        }

        this.determineDynamicNavItems();
      },

      limitlessPlus(limitlessPlus) {
        if (limitlessPlus) {
          this.determineDynamicNavItems();
        }
      },

      info: {
        immediate: true,
        handler(info) {
          if (info.authed) {
            let menuItem = {
              text: 'LOG OUT',
              method: this.confirmLogout,
            };
            this.addMenuItem(menuItem);
          }
        },
      },
    },

    async mounted() {
      this.determineDynamicNavItems();
      // we only want to track viewed event for the first time they see the experiment during the session (both versions)
      if (!this.$cookies.get(this.promoDrawerCookie)) {
        this.$cookies.set(this.promoDrawerCookie, 1);
      }
    },

    methods: {
      ...mapMutations('global', [
        'setWindowData',
      ]),

      ...mapMutations('siteNav', [
        'addMenuItem',
        'removeMenuItem',
        'setTopNavSelected',
        'setTopNavMobileNavOpen',
      ]),

      ...mapActions('siteNav', [
        'toggleMobileSiteNav',
        'toggleSearch',
        'toggleAccountNav'
      ]),

      ...mapMutations('coupons', ['setCouponDrawerOpen']),

      ...mapActions('coupons', [
        'openCouponDrawer'
      ]),

      ...mapActions('modal', ['showModal', 'showPrompt']),

      showCouponDrawer() {
        if (this.usingStickyPromoDrawer) {
          this.setCouponDrawerOpen(true);
        } else {
          this.openCouponDrawer();
        }
        this.trackMREvent('Header - Promo Drawer Opened');
      },

      openLogInModal() {
        let payload = {
          component: 'MrSignInV2Modal',
          theme: 'reset-width reset-padding close-left',
        };

        this.showModal(payload);
      },

      openAccountModal() {
        this.trackMREvent('Header - Account icon clicked');
        this.toggleAccountNav();
      },

      confirmLogout() {
        let promptPayload = {
          component: 'ConfirmDialogTemplateModal',
          theme: 'flex-width',
          confirm: this.mix_logOut,
          confirmText: 'Log Out',
          title: 'Log Out',
          text: 'Please confirm you want to sign out of your Madison Reed account.',
          centerText: true,
        };

        this.showPrompt(promptPayload);
      },

      toggleHamburger() {
        if (!this.showMensMenu) {
          this.toggleMobileSiteNav();
        } else {
          this.setTopNavMobileNavOpen(!this.topNavMobileNavOpen);
        }
      },

      determineDynamicNavItems() {
        let subscriptionMenuIndex = 1;
        let orderMenuIndex = 1;

        //- add 'Subscriptions' menu items if user has any active or past subscriptions and it is not only unlimited roots membership
        if ((this.cdata.activeSubscriber && this.hasNonMembershipSubs) || this.hasMoreThanCanceledLimitlessMembership) {
          let menuItem = {
            url: '/dashboard/subscriptions',
            text: 'Subscriptions',
            index: subscriptionMenuIndex,
          };

          this.addMenuItem(menuItem);
          orderMenuIndex++;
        }

        //- add 'Orders' menu items if user has any existing or pending orders
        if (this.cdata.ordersTotal > 0 || ((Array.isArray(this.cdata.pendingOrders) && this.cdata.pendingOrders.length > 0))) {
          let ordersMenuItem = {
            url: '/dashboard/orders',
            text: 'Orders',
            index: orderMenuIndex,
          };

          this.addMenuItem(ordersMenuItem);
        }
      }
    }
  };
</script>

<style lang="stylus">
  .mr-navigation
    .coupons
      display inline-block
      vertical-align top

    .top-wrap
      .right-wrap
        .icon-account
          position relative
          display inline-block
          vertical-align top
          margin-right 0.5rem
          .desktop-only
            display flex
            align-items center
            padding 0.4rem
            border-radius 0.25rem
            background-color #EDEDED

            svg
              margin-right 0.2rem
              width unset

          svg
            width 1.8em

          @media mq-mobile
            margin-left unset
            svg
              width 1.8em

</style>