// DO NOT ADD "Vue.use" or "Vue.component" or "Vue.mixin" here since they will be added over and over again to the same Vue instance, possibly
// creating a memory leak, use "registerGlobalsSsr.js" for that
// https://github.com/vuejs/vue/issues/5089#issuecomment-284260111

// App core
import Vue from 'vue';
import App from './SsrApp.vue';
import {createStore} from './store';
import {createRouter} from './router';
import {elementInView, getObjProperty, getObjPropertyCatchUndefined} from "@utilities/mrVueUtils";

// DO NOT ADD "Vue.use" or "Vue.component" or "Vue.mixin" here since they will be added over and over again to the same Vue instance, possibly
// creating a memory leak, use "registerGlobalsSsr.js" for that

export async function createApp() {

  // DO NOT ADD "Vue.use" or "Vue.component" or "Vue.mixin" here since they will be added over and over again to the same Vue instance, possibly
  // creating a memory leak, use "registerGlobalsSsr.js" for that
  if (process.env.NODE_ENV === 'dev') {
    Vue.config.devtools = true;
  }

  if (process.env.IS_BROWSER) {
    const Sentry = await import('@sentry/browser');

    Vue.config.errorHandler = function (err, vm, info) {
      //- have to manually log the error to console when using errorHandler
      // eslint-disable-next-line no-console
      console.error(err);

      //- send the error to Sentry for production monitoring
      if (Sentry) {
        Sentry.captureException(err);
        Sentry.setExtra('info', info);
      }
    };
  }

  const store = createStore();
  const router = createRouter();

  const app = new Vue({
    provide: {
      getObjProperty,
      getObjPropertyCatchUndefined,
      elementInView,
    },
    store,
    router,
    render: h => h(App)
  });

  return {app, store, router};
}